import {
  TextField,
} from '@mui/material';

const MediatorAppointmentDate = ({
  mediatorAppointmentDate
}) => {
  return (
    <TextField
      variant="standard"
      type="date"
      name='mediatorAppointmentDate'
      label='Date of Mediator Appointment'
      value={mediatorAppointmentDate || ''}
      InputLabelProps={{ shrink: true }}
      inputProps={{ readOnly: true }}
    />
  )
}

export default MediatorAppointmentDate
