import React from 'react';
import {
  Plugin, Template, TemplateConnector, TemplatePlaceholder,
} from '@devexpress/dx-react-core';

import {
  FormGroup,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  FormControl,
  Typography,
  capitalize,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  MultiEditor,
  NumberEditor,
  TextEditor,
  DateEditor,
  ImageEditor,
  BooleanEditor,
} from './DataTypeProviders'
import { hasError } from './validationUtils'
import { useOfflineMutation } from '@weilu/react-offix-hooks'
import { UPDATE_QUERIES, CREATE_QUERIES,
  commitCreateToServer, commitUpdateToServer } from './offixdb'
import {
  CHANGES_QUERIES,
  REJECT_MEDIATOR_RECOMMENDATION_QUERY,
} from './queries'
import { LinkToClipboard } from './LinkToClipboard'
import { CTSCaseLink } from './LinkToCTS'
import { ReportCardViewSwitcher } from './ReportCardViewSwitcher';
import ReportCard from './ReportCard';
import Activities from './Activities';
import Unavailabilities from './Unavailability';
import SelectEditor from './SelectEditor'
import MediatorRecommender from './MediatorRecommender'
import ExtraMediatorPanel from './ExtraMediatorPanel'
import {
  useHistory,
} from "react-router-dom";
import { getAvailableValues } from './utils'
import { mediatorColumns } from './columns'
import { useMutation } from '@apollo/client';
import { CTSCaseSearchEditor } from './CTSCaseSearch'

const PopupEditField = ({
  isNew, row, onChange, col, availableValues, index, readOnly,
  initialValidate, setSaveButtonText, setMsgObj, setUpdateToServerOverride,
}) => {
  React.useEffect(() => {
    if (col.conditionallySetVal) {
      const newVal = col.conditionallySetVal(row)
      if (newVal !== row[col.name]) {
        onChange({target: {name: col.name, value: newVal}})
      }
    }
  }, [row, col, onChange])

  if (isNew && col.editOnly) return ''
  if (!isNew && col.createOnly) return ''

  const rawVal = row[col.name]
  const val = col.getCellValue ? col.getCellValue(row) : rawVal
  const show = col.conditional ? col.conditional(row) : true
  const required = col.required || (show && col.conditionallyRequired)

  switch (col.type) {
    case 'text':
      return show &&
      <FormControl variant="standard" margin="normal">
        <TextEditor
          column={col}
          value={val}
          onChange={onChange}
          autoFocus={index===0}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
      </FormControl>;
    case 'select': {
      const Editor = col.editorType==='MediatorPicker' ? MediatorRecommender : SelectEditor
      return show &&
      <FormControl variant="standard" margin="normal">
        <Editor
          column={col}
          availableValues={availableValues}
          value={val}
          onChange={onChange}
          readOnly={readOnly || col.readOnly}
          disabled={col.disabled}
          row={row}
          required={required}
          initialValidate={initialValidate}
          setSaveButtonText={setSaveButtonText}
          setMsgObj={setMsgObj}
          setUpdateToServerOverride={setUpdateToServerOverride}
        />
      </FormControl>;
    }
    case 'multiselect':
      return show &&
      <FormControl variant="standard" margin="normal">
        <MultiEditor
          column={col}
          availableValues={availableValues}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
      </FormControl>;
    case 'number':
      return show &&
      <FormControl variant="standard" margin="normal">
        <NumberEditor
          column={col}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
      </FormControl>;
    case 'date':
      return show &&
      <FormControl variant="standard" margin="normal">
        <DateEditor
          column={col}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
      </FormControl>;
    case 'image':
      return show &&
      <FormControl
        variant="standard"
        margin="normal"
        style={{
          flexDirection: "row",
          justifyContent: "center"
        }}>
        <ImageEditor
          column={col}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
        />
      </FormControl>;
    case 'boolean':
      return show &&
        <BooleanEditor
          column={col}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
    case 'ctsCaseLookup':
      return show &&
      <FormControl variant="standard" margin="normal">
        <CTSCaseSearchEditor
          row={row}
          column={col}
          value={val}
          onChange={onChange}
          readOnly={readOnly}
          required={required}
          initialValidate={initialValidate}
        />
      </FormControl>;
    default:
      return ''
  }
}

const Issues = ({row, readOnly}) => {
  if (readOnly || !row.issues?.length)
    return ''

  return (
    <Alert severity="error">
      <AlertTitle>Please fix the following data issues for this record:</AlertTitle>
        { row.issues.map((issue, i) => (
          <span key={i}>{issue}<br/></span>
        )) }
    </Alert>
  )
}

const FormFields = ({
  i,
  activeStep,
  isNew,
  row,
  columns,
  readOnly,
  onChange,
  availableValues,
  initialValidate,
  setMsgObj,
  setSaveButtonText,
  setUpdateToServerOverride,
}) => {
  if (i!==activeStep) return ''

  return (<>
    { !isNew && i===0 &&
      <Issues row={row} readOnly={readOnly} />
    }
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormGroup>
          {columns.map((col, index) => (
            <PopupEditField
              isNew={isNew}
              row={row}
              onChange={onChange}
              col={col}
              availableValues={availableValues}
              key={index}
              index={index}
              readOnly={readOnly}
              initialValidate={initialValidate}
              setSaveButtonText={setSaveButtonText}
              setMsgObj={setMsgObj}
              setUpdateToServerOverride={setUpdateToServerOverride}
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  </>)
}

const Popup = ({
  isNew,
  row,
  onChange,
  onApplyChanges,
  onCancelChanges,
  open,
  availableValues,
  columns,
  readOnly,
  name,
  pluralName,
  friendlyID,
  reportCardParams,
  steps,
  setMsgObj,
  rawMetaData,
  setUpdateToServerOverride,
}) => {
  const getTitleName = React.useCallback((row) => {
    const defaultName = `${capitalize(name)} Details`

    if (row?.name) {
      return row.name
    }

    if (row?.user?.name) {
      return row.user.name
    }

    if (row?.[friendlyID]) {
      return row[friendlyID]
    }

    return defaultName
  }, [name, friendlyID])

  const [showReportCard, setShowReportCard] = React.useState(false)
  const [saveButtonText, setSaveButtonText] = React.useState('Save')

  const switchView = React.useCallback((newView) => {
    if (reportCardParams && newView === 'dashboard') {
      setShowReportCard(true)
    } else {
      setShowReportCard(false)
    }
  }, [setShowReportCard, reportCardParams])

  const onCancel = React.useCallback(() => {
    setShowReportCard(false)
    setActiveStep(0)
    setInitialValidate(false)
    onCancelChanges()
  }, [setShowReportCard, onCancelChanges])

  const [activeStep, setActiveStep] = React.useState(0);

  const groupedFormFields = React.useMemo(() => {
    // do not show steps if it's read-only
    // because the ability to move to the next step requires passing validation
    if (steps && !readOnly) {
      return steps.map(names => (
        columns.filter(c => names.includes(c.name))
      ))
    }
    return [columns]
  }, [steps, columns, readOnly])

  const [initialValidate, setInitialValidate] = React.useState(false)

  // cases only: prepare right panel content – monkey patch available values as the key is different between cases & mediators
  const mediatorAvailableValues = getAvailableValues(rawMetaData, mediatorColumns)
  mediatorAvailableValues['mediators'] = availableValues['mediator']

  const isLastStep = React.useMemo(() => (
    activeStep === groupedFormFields.length-1
  ), [activeStep, groupedFormFields.length])

  const history = useHistory();
  const onNextOrSave = React.useCallback(async () => {
    const fields = groupedFormFields[activeStep]
    if (hasError({row, columns: fields})) {
      setInitialValidate(true)
      setMsgObj({message: 'Please fix the fields with error tags', type: 'error'})
      return
    }
    setInitialValidate(false)

    const idFromServer = await onApplyChanges(steps?.[activeStep], isLastStep)

    if (idFromServer) {
      if (isLastStep) {
        setActiveStep(0)
        setShowReportCard(false)

        const gridPath = `/${pluralName}`
        if (history.location.pathname !== gridPath) {
          history.push(gridPath)
        }
      } else {
        setActiveStep(activeStep+1)

        const path = `/${pluralName}/${idFromServer}`
        if (history.location.pathname !== path) {
          history.push(path)
        }
      }
    }
  }, [
    onApplyChanges,
    row,
    activeStep,
    groupedFormFields,
    setMsgObj,
    pluralName,
    history,
    steps,
    isLastStep,
  ])

  const onBack = React.useCallback(() => {
    //TODO: important! do not allow going back if hasError
    setActiveStep(activeStep - 1)
  }, [activeStep])

  const showSaveButton = React.useMemo(() => {
    const isButtonTextBlank = !saveButtonText
    return (!readOnly && !(isLastStep && isButtonTextBlank))
  }, [readOnly, isLastStep, saveButtonText])

  return (
  <Dialog open={open} onClose={onCancel}
          scroll="paper"
          fullWidth maxWidth="lg">
    <DialogTitle id="scroll-dialog-title">
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item>
          <Typography variant="h5">
            {getTitleName(row)}
            <LinkToClipboard show={!isNew} />
            {row.ctsCaseId && <CTSCaseLink ctsCaseId={row.ctsCaseId} /> }
          </Typography>
        </Grid>
        {reportCardParams &&
          <Grid item xs={3}>
            <ReportCardViewSwitcher currentView='data' onChange={switchView} />
          </Grid>
        }
      </Grid>
    </DialogTitle>
    <DialogContent dividers={true} >
      <Grid container spacing={3} style={{
        minHeight: "74vh",
      }}>
        <Grid item xs={6}>
          <ReportCard queryParams={reportCardParams} show={showReportCard} />
          { !showReportCard &&
            groupedFormFields.map((fields, i) => {
              return (
                <FormFields
                  i={i}
                  key={i}
                  activeStep={activeStep}
                  isNew={isNew}
                  row={row}
                  columns={fields}
                  readOnly={readOnly}
                  onChange={onChange}
                  availableValues={availableValues}
                  initialValidate={initialValidate}
                  setMsgObj={setMsgObj}
                  setSaveButtonText={setSaveButtonText}
                  setUpdateToServerOverride={setUpdateToServerOverride}
                />
              )
            })
          }
        </Grid>
        <Grid item xs={6}>
          { activeStep > 0 ?
            <ExtraMediatorPanel mediator={row.mediator} availableValues={mediatorAvailableValues} />
            : <React.Fragment>
              {name == 'mediator' &&
                <Unavailabilities
                  mediatorId={row.id}
                  setMsgObj={setMsgObj}
                  refetchQueries={[{
                    query: CHANGES_QUERIES['mediator'],
                    variables: { id: row.id },
                  }]}
                />
              }
              <Activities name={name} rowId={row.id} />
          </React.Fragment>
          }
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      {(groupedFormFields.length > 1) && !!activeStep &&
        <Button onClick={onBack}>
          Back to {name} details
        </Button>
      }
      <Button onClick={onCancel} color="primary">
        {readOnly ? "Close" : "Cancel"}
      </Button>
      {showSaveButton &&
        <Button onClick={onNextOrSave} color="primary">
          {isLastStep ? saveButtonText : 'Save & Next'}
        </Button>
      }
    </DialogActions>
  </Dialog>
  )
}

const PopupWrapper = (
  {
    columns,
    availableValues,
    rows,
    singleRecordRow,
    getRowId,
    addedRows,
    editingRowIds,
    createRowChange,
    rowChanges,
    changeRow, changeAddedRow, commitChangedRows, commitAddedRows,
    stopEditRows, cancelAddedRows, cancelChangedRows, commitDeletedRows,
    setMsgObj, readOnly, name, pluralName, friendlyID, getReportCardParams,
    steps, rawMetaData,
  },
) => {
  const isNew = addedRows.length > 0;
  let editedRow;
  let rowId;
  if (isNew) {
    rowId = 0;
    editedRow = addedRows[rowId];
  } else {
    [rowId] = editingRowIds;
    const targetRow = rows.filter(row => getRowId(row) === rowId)[0] || singleRecordRow;
    editedRow = { ...targetRow, ...rowChanges[rowId] };
  }

  const skipChangesQuery = !CHANGES_QUERIES[name] || !rowId || rowId < 0
  const refetchQueries=[{
    query: CHANGES_QUERIES[name],
    variables: { id: rowId },
    skip: skipChangesQuery, // this doesn't seem to work
  }]
  const [updateRecord] = useOfflineMutation(UPDATE_QUERIES[name], {refetchQueries})
  const [createRecord] = useOfflineMutation(CREATE_QUERIES[name], {refetchQueries})

  const processValueChange = React.useCallback(({ target: { name, value } }) => {
    const changeArgs = {
      rowId,
      change: createRowChange(editedRow, value, name),
    };
    if (isNew) {
      changeAddedRow(changeArgs);
    } else {
      changeRow(changeArgs);
    }
  }, [isNew, rowId, createRowChange, changeAddedRow, changeRow, editedRow])

  const rowIds = React.useMemo(() => isNew ? [rowId] : editingRowIds,
    [isNew, rowId, editingRowIds])

  const updateRowIDs = React.useCallback((row, newIds) => {
    changeRow({
      rowId: row.id,
      change: createRowChange(row, newIds[friendlyID], friendlyID),
    })
  }, [createRowChange, changeRow, friendlyID])

  const [rejectRecommendation] = useMutation(REJECT_MEDIATOR_RECOMMENDATION_QUERY)

  const [updateToServerOverride, setUpdateToServerOverride] = React.useState(undefined)

  const applyChanges = React.useCallback(async (activeStepFields, isLastStep) => {
    const id = rowIds[0]
    if (isNew) {
      const row = addedRows[id]
      const record = await commitCreateToServer({
        editedRow: row,
        createRecord,
        setMsgObj,
        availableValues,
        commitDeletedRows,
        updateRowIDs,
        name,
      })
      if (record?.id) {
        // attach additional info from server to the added row
        ['macNumber', 'issues', 'id', 'caseNumber'].forEach(key => {
          if (key in record) {
            changeAddedRow({
              rowId: id,
              change: createRowChange(row, record[key], key)
            })
          }
        })
        commitAddedRows({ rowIds }) // note it will be the placeholder ID
        return record.id
      }
    } else {
      // handling of "parties request" and "court named" rejection scenarios
      const recommendation = rowChanges[id]?.rejectRecommendation
      if (recommendation) {
        const {rejectReason, rejectReasonOther, rejectReasonCourtNamedBy} = recommendation
        const mediatorId = recommendation.mediator?.id
        if (mediatorId && rejectReason && !recommendation.hasError) {
          rejectRecommendation({variables: {
            mediator: {id: mediatorId},
            case: {id},
            rejectReason, rejectReasonOther, rejectReasonCourtNamedBy
          }})
        }
      }

      const activeChanges = (rowChanges[id] && activeStepFields) ?
        Object.fromEntries(Object.entries(rowChanges[id]).filter(keyVal => activeStepFields.includes(keyVal[0])))
        : rowChanges[id]

      const row = {id, ...activeChanges}

      let record;
      if (typeof updateToServerOverride === 'function' && isLastStep) {
        record = await updateToServerOverride()
      } else {
        // if there is no change, skip sending request to DB
        if (!activeChanges || Object.values(activeChanges).length == 0) {
          return id
        }

        record = await commitUpdateToServer({
          editedRow: row, updateRecord, setMsgObj, availableValues, name
        })
      }

      if (record) {
        // attach additional info from server to the added row
        ['mediatorAppointmentDate', 'mediator', 'mediatorIsRecommended'].forEach(key => {
          if (key in record) {
            changeRow({
              rowId: id,
              change: createRowChange(row, record[key], key)
            })
          }
        })
        stopEditRows({ rowIds });
        commitChangedRows({ rowIds })
        return id
      }
    }
  }, [
      isNew,
      rowIds,
      commitAddedRows,
      changeAddedRow,
      changeRow,
      createRowChange,
      stopEditRows,
      commitChangedRows,
      createRecord,
      updateRecord,
      setMsgObj,
      availableValues,
      commitDeletedRows,
      updateRowIDs,
      rowChanges,
      addedRows,
      name,
      rejectRecommendation,
      updateToServerOverride,
  ])

  const cancelChanges = React.useCallback(() => {
    if (isNew) {
      cancelAddedRows({ rowIds });
    } else {
      stopEditRows({ rowIds });
      cancelChangedRows({ rowIds });
    }
  }, [isNew, rowIds, cancelAddedRows, stopEditRows, cancelChangedRows])

  const open = isNew || editingRowIds.length > 0

  const reportCardParams = React.useMemo(() => (
    getReportCardParams ? getReportCardParams(editedRow) : null
  ), [editedRow, getReportCardParams])

  return (
    <Popup
      isNew={isNew}
      open={open}
      row={editedRow}
      onChange={processValueChange}
      onApplyChanges={applyChanges}
      onCancelChanges={cancelChanges}
      columns={columns}
      availableValues={availableValues}
      readOnly={readOnly}
      name={name}
      pluralName={pluralName}
      friendlyID={friendlyID}
      reportCardParams={reportCardParams}
      steps={steps}
      setMsgObj={setMsgObj}
      rawMetaData={rawMetaData}
      setUpdateToServerOverride={setUpdateToServerOverride}
    />
  );
}

const PopupEditing = (props) => (
  <Plugin>
    <Template name="popupEditing">
      <TemplateConnector>
        {(a, b) => (
          <PopupWrapper {...props} {...a} {...b} />
        )}
      </TemplateConnector>
    </Template>
    <Template name="root">
      <TemplatePlaceholder />
      <TemplatePlaceholder name="popupEditing" />
    </Template>
  </Plugin>
)

export {PopupEditing, PopupEditField}
