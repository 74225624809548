import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  EditingState,
  SortingState,
  SearchState,
  FilteringState,
  VirtualTableState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  TableHeaderRow,
  TableFilterRow,
  TableEditColumn,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  DragDropProvider,
  TableColumnReordering,
  VirtualTable,
  TableFixedColumns,
} from '@devexpress/dx-react-grid-material-ui';

import {
  SelectTypeProvider,
  MultiTypeProvider,
  BooleanTypeProvider,
  TextTypeProvider,
  DateTypeProvider,
  ImageTypeProvider,
  PercentageTypeProvider,
  NotificationTypeProvider,
  CTSCaseSearchTypeProvider,
} from './DataTypeProviders'

import { useOfflineMutation } from '@weilu/react-offix-hooks'
import { useQuery } from '@apollo/client';
import { DELETE_QUERIES, commitDeleteToServer } from './offixdb'
import CommandButtons from './CommandButtons'
import { PopupEditing } from './PopupEditor'
import SearchPanel from './DebouncedSearchPanel'
import FilterChooser from './FilterChooser'
import ColumnChooser from './ColumnChooser'
import { ViewSwitcher } from './ViewSwitcher';
import { RowCount } from './RowCount';
import { getFetchQuery, getSingleRecordFetchQuery } from './queries';
import { deepEqual } from './utils'
import { canPerform } from './auth'

import {
  useHistory,
  useParams,
} from "react-router-dom";


const TableRow = props => {
  return <VirtualTable.Row {...props} style={{ height: 53 }} />;
}

const getRowId = row => row.id;

function getColumnNames(type, columns) {
  return columns.reduce((acc, col) => {
    if (col.type === type) acc.push(col.name)
    return acc
  }, [])
}

const SortLabel = ({ column, ...restProps }) => {
  return (
    <TableHeaderRow.SortLabel
      column={column}
      getMessage={() => column.title }
      {...restProps}
    />
  );
};

const TableTitle = ({children}) => {
  const title = ['Profile Picture', 'Data Check'].includes(children) ? ' ' : children
  return (

    <span style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
      {title}
    </span>
  );
};

const GridRoot = (props) => {
  return <Grid.Root {...props}  style={{height: '100%' }} />
}

function getSortBy(sorting, columns) {
  if (!sorting?.length) {
    return
  }

  const sortObj = sorting[0]
  const column = columns.find(col => (col.name === sortObj.columnName))

  let orderBy = sortObj.columnName
  if (column.sortName) {
    orderBy = column.sortName
  } else if (column.type === 'select') {
    orderBy = orderBy + '__name'
  }

  if (sortObj.direction === 'desc') {
    orderBy = '-' + orderBy
  }

  // Always have pk as the secondary ordering key to ensure sorting is stable
  // This is super important for ensuring pagination works correctly
  orderBy = orderBy + ',pk'
  return orderBy
}

const VIRTUAL_PAGE_SIZE = 20

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

function getSortingStateColumnExtensions (columnsToDisable) {
  return columnsToDisable.map(name => ({columnName: name, sortingEnabled: false}))
}

function getFilteringStateColumnExtensions (columnsToDisable) {
  return columnsToDisable.map(name => ({columnName: name, filteringEnabled: false}))
}

function filterParamsFromFilters(filters, columns) {
  const params = {}
  filters.forEach(f => {
    let val = f.value
    if (val && typeof(val) === 'object') {
      if (val.id) {
        val = val.id
      } else {
        val = val.map(v => v.id)
      }
    }
    const column = columns.find(col => (col.name === f.columnName))
    const paramName = column.filterName ?? column.sortName ?? f.columnName
    params[paramName] = val
  })
  return params
}

function shouldDebounce(filters) {
  return filters.some(f => typeof(f.value) === 'string')
}

function useFiltersDebounce(filters, columns, delay) {
  const defaultValue = shouldDebounce(filters) ? {} : filterParamsFromFilters(filters, columns)
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useEffect(
    () => {
      // only debounce when there's any text filter
      if (!shouldDebounce(filters)) {
        return setDebouncedValue(filterParamsFromFilters(filters, columns))
      }

      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(filterParamsFromFilters(filters, columns))
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [filters, delay, columns] // Only re-call effect on change
  );
  return debouncedValue;
}

function rowMissingFromRows(row, rows) {
  return row?.id && !rows.map(r => r.id).includes(row.id)
}

function getInitialColumnWidths(columns) {
  return columns.map(c => {
    var width = 150
    if(['image', 'notification'].includes(c.type))
      width = 50
    else if (c.title == 'Access Groups') {
      width = 400
    }
    return {
      columnName: c.name,
      width: width
    }
  })
}

export default function MainGrid({
  columns,
  availableValues,
  rawMetaData,
  editingColumnExtensions,
  setMsgObj,
  permissions,
  name,
  pluralName,
  friendlyID,
  defaultHiddenColumnNames,
  defaultFriendlyIDValue,
  defaultFilters,
  getReportCardParams,
  steps,
}) {
  const [singleRecordManuallyAttached, setSingleRecordManuallyAttached] = useState(false)
  const [singleRecordRow, setSingleRecordRow] = useState()
  const [totalRowCount, setTotalRowCount] = useState(0)
  const defaultSorting = [{ columnName: friendlyID, direction: 'desc' }]
  const [sorting, setSorting] = useState(defaultSorting)
  const [searchValue, setSearchValue] = useState('')
  const [filters, setFilters] = useState(defaultFilters || []);
  const debouncedFilterParams = useFiltersDebounce(filters, columns, 300);
  const defaultVariables = {
    orderBy: getSortBy(defaultSorting, columns),
    offset: 0,
    first: VIRTUAL_PAGE_SIZE * 2,
    search: searchValue,
    ...debouncedFilterParams
  }
  const [variables, setVariables] = useState(defaultVariables)
  const [skip, setSkip] = useState(0)
  const getRemoteRows = (requestedSkip, take) => {
    setVariables((variables) => {
      if (requestedSkip !== variables.offset || take !== variables.first) {
        return  {...variables, offset: requestedSkip, first: take}
      } else {
        return variables
      }
    })
  }

  const [rows, setRows] = useState([])
  const { id } = useParams()
  const [editingRowIds, setEditingRowIds] = useState([])
  const history = useHistory();
  const [initialFetchDone, setInitialFetchDone] = useState(false)

  const maybeRemoveSingleRecordFromRows = useCallback((singleRecordRowId) => {
    setRows(rows => {
      if (singleRecordManuallyAttached) {
        const strippedRows = rows.filter(r => r.id !== singleRecordRowId)
        console.log(`${new Date()}: Removed manually attached singleRecordRow from rows. Expect ${rows.length} - ${strippedRows.length} = 1`)
        return strippedRows
      } else {
        return rows
      }
    })
    setSingleRecordManuallyAttached(false)
    setSingleRecordRow(null)
  }, [singleRecordManuallyAttached])

  const updateSingleRecordRowAndEditingRowIds = useCallback((row) => {
    setSingleRecordRow(currentRow => {
      if (!deepEqual(currentRow, row)) {
        return row
      }
      return currentRow
    })

    setEditingRowIds(editingRowIds => {
      const newEditingRowIds = row?.id ? [row.id] : []
      if (!deepEqual(editingRowIds, newEditingRowIds)) {
        return newEditingRowIds
      }
      return editingRowIds
    })

  }, [])

  // effect chains:
  // id -> singleRecordFetch (or unset) -> singleRecordRow & editingRowIds & rows -> url path / id
  const onSingleRecordData = useCallback((data) => {
    if (id) { // ensure user has not yet closed the popup
      const dataRow = data[name]
      updateSingleRecordRowAndEditingRowIds(dataRow)
      setRows(rows => {
        const singleRecordRowMissing = rowMissingFromRows(dataRow, rows)
        setSingleRecordManuallyAttached(singleRecordRowMissing)
        if (singleRecordRowMissing) {
          console.log(`${new Date()}: Updating rows ${rows.length} with a manually attached row`)
          return rows.concat([dataRow])
        }
        return rows
      })
      setInitialFetchDone(true)
    }
  }, [name, id, updateSingleRecordRowAndEditingRowIds])

  const onSingleRecordError = useCallback(() => {
    updateSingleRecordRowAndEditingRowIds(null)
    setInitialFetchDone(true)
  }, [updateSingleRecordRowAndEditingRowIds])

  const singleRecordFetchQuery = React.useMemo(() => (
    getSingleRecordFetchQuery(permissions, pluralName)
  ), [permissions, pluralName])

  useQuery(singleRecordFetchQuery, {
    variables: {id: id},
    fetchPolicy: "network-only", // if cache-and-network slow network may overwrite user's edit
    onCompleted: onSingleRecordData,
    onError: onSingleRecordError,
    skip: !id
  })

  useEffect(() => {
    // do not mess with URL if nothing is loaded yet or when request inflight
    if (!initialFetchDone) {
      return
    }

    let path
    if (!editingRowIds || editingRowIds.length < 1) {
      path = `/${pluralName}`
      if (history.location.pathname !== path) {
        history.push(path)
      }
    } else if (editingRowIds.length === 1) {
      path = `/${pluralName}/${editingRowIds[0]}`
      if (history.location.pathname !== path) {
        history.push(path)
      }
    }
  }, [editingRowIds, history, pluralName, initialFetchDone])

  // unset the single record row if the url no longer contains the ID
  useEffect(() => {
    if (!id && singleRecordRow?.id) {
      maybeRemoveSingleRecordFromRows(singleRecordRow?.id)
      updateSingleRecordRowAndEditingRowIds(null)
    }
  }, [id, singleRecordRow, updateSingleRecordRowAndEditingRowIds,
    maybeRemoveSingleRecordFromRows])

  // sorting effect
  useEffect(() => {
    const sortBy = getSortBy(sorting, columns)
    setVariables((variables) => {
      if (sortBy !== variables.orderBy) {
        return {...variables, orderBy: sortBy}
      }
      return variables
    })
  }, [columns, sorting])

  // filtering effect
  useEffect(() => {
    setVariables((variables) => {
      const {orderBy, offset, first, search, ...filterVars} = variables
      if (deepEqual(debouncedFilterParams, filterVars)) {
        return variables
      }
      return {orderBy, offset, first, search, ...debouncedFilterParams}
    })
  }, [columns, debouncedFilterParams])

  // search effect
  useEffect(() => {
    setVariables((variables) => {
      if (searchValue !== variables.search) {
        return {...variables, search: searchValue}
      }
      return variables
    })
  }, [columns, searchValue])

  const maybeSetRows = useCallback((newRows) => {
    setRows(rows => {
      if (!deepEqual(rows, newRows)) {
        console.log(`${new Date()}: Updating rows ${rows.length} with newRows ${newRows.length}`)
        return newRows
      }
      return rows
    })
  }, [])

  const onData = useCallback((data) => {
    const paginatedKey = `all${capitalize(pluralName)}`
    const dataRows = data[paginatedKey]['edges'].map(e => e.node)
    const singleRecordRowMissing = rowMissingFromRows(singleRecordRow, dataRows)
    maybeSetRows(singleRecordRowMissing ? dataRows.concat([singleRecordRow]) : dataRows)
    setSingleRecordManuallyAttached(singleRecordRowMissing)
    console.log(`${new Date()}: Data fetched from server record count: ${dataRows?.length}, singleRecordManuallyAttached: ${singleRecordRowMissing}`)

    const totalCount = data[paginatedKey]['totalCount']
    setTotalRowCount(totalCount)
    setSkip(Math.min(totalCount, variables.offset))
    if (!id) {
      setInitialFetchDone(true)
    }
  }, [pluralName, variables.offset, id, singleRecordRow, maybeSetRows])

  const fetchQuery = React.useMemo(() => (
    getFetchQuery(permissions, pluralName)
  ), [permissions, pluralName])

  // TODO: only poll data periodically for the last request.
  // Maybe use `stopPolling` function on older queries
  const { loading, error, refetch } = useQuery(fetchQuery, {
    variables,
    fetchPolicy: "cache-and-network",
    pollInterval: 0, // disable pulling as it could flood server with queries after a series of filtering / scrolling actions
    onCompleted: onData,
  })

  useEffect(() => {
    refetch(variables)
  }, [variables, refetch])

  const readOnly = useMemo(() => (
    canPerform('view', name, permissions) && !canPerform('change', name, permissions)
  ), [permissions, name])

  const notificationColIndex = columns.findIndex(c => c.type === 'notification')
  if (notificationColIndex >= 0 && readOnly) {
    columns.splice(notificationColIndex, 1)
  }

  const selectColumns = getColumnNames('select', columns)
  const multiColumns = getColumnNames('multiselect', columns)
  const booleanColumns = getColumnNames('boolean', columns)
  const textColumns = getColumnNames('text', columns)
  const dateColumns = getColumnNames('date', columns)
  const imageColumns = getColumnNames('image', columns)
  const percentageColumns = getColumnNames('percent', columns)
  const numberColumns = getColumnNames('number', columns)
  const notificationColumns = getColumnNames('notification', columns)
  const formulaNoFilterColumns = getColumnNames('formulaNoFilter', columns)
  const ctsCaseLookupColumns = getColumnNames('ctsCaseLookup', columns)

  const sortingDisabled = multiColumns.concat(imageColumns)
  const filteringDisabled = dateColumns.concat(imageColumns)
    .concat(percentageColumns)
    .concat(numberColumns)
    .concat(notificationColumns)
    .concat(formulaNoFilterColumns)

  const initialColWidths = getInitialColumnWidths(columns)

  const [addedRows, setAddedRows] = useState([])

  // friendlyID is a required field for edit
  // so this bypasses frontend validation of friendlyID field for mediator create
  const changeAddedRows = useCallback((value) => {
    if (defaultFriendlyIDValue === undefined)
      return setAddedRows(value)

    const initialized = value.map(row => (Object.keys(row).length ? row : { [friendlyID]: defaultFriendlyIDValue }))
    setAddedRows(initialized);
  }, [friendlyID, defaultFriendlyIDValue])

  const [columnWidths, setColumnWidths] = useState(initialColWidths)

  const initialColOrder = columns.map(c => (c.name))
  const [columnOrder, setColumnOrder] = useState(initialColOrder);

  const deleteQuery = DELETE_QUERIES[name]
  const [deleteRecord] = useOfflineMutation(deleteQuery)

  const commitChanges = useCallback(async ({ added, changed, deleted }) => {
    let changedRows;

    if (added) {
      changedRows = added.concat(rows)
    }

    if (changed) {
      changedRows = rows.map(row => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    }

    if (deleted) {
      const success = await commitDeleteToServer({deleted, deleteRecord, setMsgObj, name})
      const deletedSet = new Set(deleted);
      changedRows = success ? rows.filter(row => !deletedSet.has(row.id)) : rows
    }

    setRows(changedRows);

  }, [rows, setRows, deleteRecord, setMsgObj, name])

  const [leftFixedColumns] = useState([TableEditColumn.COLUMN_TYPE]);

  const onFiltersChange = useCallback((filters) => {
    const slimFilters = filters.filter(f => f?.value?.id !== "SelectFilterAny")
    setFilters(slimFilters)
  }, [])

  const CommandComponent = React.useMemo(() => (CommandButtons(readOnly)), [readOnly])

  if (error) {
    var message = "Sorry, something went wrong. Please refresh the page and try again. If the issue remains, please use the feedback button to report the issue."
    if (!window.navigator.onLine) {
      message = 'You appear to have lost internet connection. Please refresh the page and try again later.'
    }
    return <div style={{textAlign: "center", marginTop: "40vh"}}> {message} </div>
  }

  return(
    <div style={{textAlign: "center", height: '86vh', position: "relative" }}>
      <ViewSwitcher pathName={pluralName} absolutePositioned={true} />
      <Grid rows={rows} columns={columns} getRowId={getRowId} rootComponent={GridRoot}>
        <EditingState
          columnExtensions={editingColumnExtensions}
          onCommitChanges={commitChanges}
          addedRows={addedRows}
          onAddedRowsChange={changeAddedRows}
          editingRowIds={editingRowIds}
          onEditingRowIdsChange={setEditingRowIds}
        />
        <SelectTypeProvider for={selectColumns} availableValues={availableValues}/>
        <MultiTypeProvider for={multiColumns} availableValues={availableValues} />
        <BooleanTypeProvider for={booleanColumns} />
        <TextTypeProvider for={textColumns} />
        <DateTypeProvider for={dateColumns} />
        <ImageTypeProvider for={imageColumns} />
        <PercentageTypeProvider for={percentageColumns} />
        <NotificationTypeProvider for={notificationColumns} />
        <CTSCaseSearchTypeProvider for={ctsCaseLookupColumns} />
        <DragDropProvider />
        <SortingState
          sorting={sorting}
          onSortingChange={setSorting}
          columnExtensions={getSortingStateColumnExtensions(sortingDisabled)}
        />
        <SearchState
          onValueChange={setSearchValue}
        />
        <FilteringState
          filters={filters}
          onFiltersChange={onFiltersChange}
          columnExtensions={getFilteringStateColumnExtensions(filteringDisabled)}
        />
        <VirtualTableState
          loading={loading}
          totalRowCount={totalRowCount}
          pageSize={VIRTUAL_PAGE_SIZE}
          skip={skip}
          getRows={getRemoteRows}
        />
        <VirtualTable
          height="auto"
          rowComponent={TableRow}
          estimatedRowHeight={53}
        />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} titleComponent={TableTitle}/>
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <Toolbar />
        <RowCount totalRowCount={totalRowCount}/>
        <SearchPanel />
        <FilterChooser filters={filters} setFilters={setFilters} columns={columns}/>
        <TableFilterRow />
        <ColumnChooser />
        <TableEditColumn
          showAddCommand={canPerform('add', name, permissions)}
          showEditCommand={true}
          showDeleteCommand={canPerform('delete', name, permissions)}
          commandComponent={CommandComponent}
        />
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />
        <PopupEditing
          columns={columns}
          availableValues={availableValues}
          setMsgObj={setMsgObj}
          readOnly={readOnly}
          name={name}
          pluralName={pluralName}
          friendlyID={friendlyID}
          getReportCardParams={getReportCardParams}
          steps={steps}
          rawMetaData={rawMetaData}
          singleRecordRow={singleRecordRow}
        />
        <TableFixedColumns
          leftColumns={leftFixedColumns}
        />
      </Grid>
    </div>
  )
}
