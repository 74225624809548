import React from 'react';

import {
  Alert,
  Button,
  Chip,
  Grid,
  FormControl,
  Typography,
  Collapse,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import IconButton from '@mui/material/IconButton';
import SelectEditor from './SelectEditor'
import { useQuery, useMutation } from '@apollo/client';
import {
  DUMMY_QUERY,
  MEDIATOR_RECOMMENDATIONS_QUERY,
  REJECT_MEDIATOR_RECOMMENDATION_QUERY,
  ACCEPT_MEDIATOR_RECOMMENDATION_QUERY,
} from './queries'
import MediatorPicker from './MediatorPicker'
import MediatorAppointmentDate from './MediatorAppointmentDate'
import { SELECT_FALLBACK_VALUE, wrapSelectChangeEvent, deepEqual } from './utils'

const MEDIATOR_DECLINED = "Mediator declined the case"
const MEDIATOR_UNREACHABLE = "Mediator could not be reached"
const OTHER = "Other"

function getToday() {
  return new Date().toISOString().split("T")[0]
}

const MediatorRecommender = ({
  column, availableValues, value, onChange, setMsgObj,
  row, disabled, readOnly, required, setSaveButtonText,
  setUpdateToServerOverride,
}) => {
  const [openInfo, setOpenInfo] = React.useState(true);
  const [recommendation, setRecommendation] = React.useState({
    mediator: row.mediator, 
    acceptedAt: row.mediatorAppointmentDate,
  })
  const [showDeclineReason, setShowDeclineReason] = React.useState(false)
  const [rejectError, setRejectError] = React.useState('')
  const [rejectOtherError, setRejectOtherError] = React.useState('')
  const [recommended, setRecommended] = React.useState(false)
  const [exhaustedCleared, setExhaustedCleared] = React.useState(false)

  const [mediatorAppointmentDate, setMediatorAppointmentDate] = React.useState(row.mediatorAppointmentDate)

  const specificMediator = React.useMemo(() => {
    return ['COURT_NAMED', 'PARTIES_REQUESTED'].includes(recommendation.rejectReason)
  }, [recommendation.rejectReason])

  const isManualAssignment = React.useMemo(() => {
    return(
      !recommendation.mediator ||
      specificMediator ||
      (row.mediatorAppointmentDate && !row.mediatorIsRecommended)
    )
  }, [
    specificMediator,
    recommendation.mediator,
    row.mediatorAppointmentDate,
    row.mediatorIsRecommended,
  ])

  const { loading, error, data, refetch } = useQuery(MEDIATOR_RECOMMENDATIONS_QUERY || DUMMY_QUERY, {
    fetchPolicy: "network-only",
    skip: !row.id || row.id < 0 || readOnly || row.mediatorAppointmentDate,
    variables: { caseId: row.id },
  })

  const rejectRefetchQueries = [{
    query: MEDIATOR_RECOMMENDATIONS_QUERY,
    variables: { caseId: row.id },
  }]
  const onRejectError = (err) => {
    const errMsg = err.graphQLErrors ? err.graphQLErrors[0].message : err.message
    setRejectError(errMsg)
  }
  const onRejectCompleted = React.useCallback(() => {
    setRejectError('')
    if (row.mediatorAppointmentDate) {
      onChange({target: {name: 'mediatorAppointmentDate', value: null}})
    }
  }, [onChange, row.mediatorAppointmentDate])
  const [rejectRecommendation] = useMutation(REJECT_MEDIATOR_RECOMMENDATION_QUERY, {
    onCompleted: onRejectCompleted,
    onError: onRejectError,
    refetchQueries: rejectRefetchQueries,
  })

  const saveRejectToDB = React.useCallback(() => {
    const mediatorId = recommendation?.mediator?.id
    const rejectReason = recommendation?.rejectReason
    if (mediatorId && rejectReason) {
      const rejectReasonOther = recommendation?.rejectReasonOther
      const rejectReasonCourtNamedBy = recommendation?.rejectReasonCourtNamedBy
      rejectRecommendation({variables: {
        mediator: {id: mediatorId},
        case: {id: row.id},
        rejectReason, rejectReasonOther, rejectReasonCourtNamedBy
      }})
    }

    setShowDeclineReason(false)
  }, [
    recommendation?.mediator?.id,
    recommendation?.rejectReason,
    recommendation?.rejectReasonOther,
    recommendation?.rejectReasonCourtNamedBy,
    rejectRecommendation,
    row.id,
  ])

  const validateAndSaveRejectToDB = React.useCallback(() => {
    if (recommendation.rejectReason == 'OTHER' && !recommendation.rejectReasonOther) {
      setRejectOtherError('Required')
      return true // keep the alert box open
    }
    saveRejectToDB()
  }, [recommendation, saveRejectToDB])

  const onAcceptError = React.useCallback((err) => {
    const errMsg = err.graphQLErrors ? err.graphQLErrors[0].message : err.message
    setMsgObj({message: errMsg, type: 'error'})
    refetch()
  }, [setMsgObj, refetch])

  const [acceptRecommendation] = useMutation(ACCEPT_MEDIATOR_RECOMMENDATION_QUERY, {
    onError: onAcceptError,
  })
  const saveAcceptToDB = React.useCallback(async () => {
    const mediatorId = recommendation?.mediator?.id
    if (mediatorId) {
      const response = await acceptRecommendation({variables: {
        mediator: {id: mediatorId},
        case: {id: row.id},
      }})

      if (!response?.data) return null

      const record = response.data["acceptMediatorRecommendation"]["recommendation"]
      setMsgObj({message: 'Mediator appointed!', type: 'success'})
      const updatedCase = {
        mediator: record.mediator,
        mediatorAppointmentDate: record.acceptedAt.split('T')[0],
        mediatorIsRecommended: true,
      }
      return updatedCase
    }
    return null
  }, [recommendation?.mediator?.id, acceptRecommendation, setMsgObj, row.id])

  React.useEffect(() => {
    let saveButtonText;
    let updateFunc;
    if (isManualAssignment) {
      saveButtonText = 'Save'
      updateFunc = undefined
    } else if (!row.mediatorAppointmentDate) {
      updateFunc = () => saveAcceptToDB
      saveButtonText = 'Accept mediator'
    }
    setSaveButtonText(saveButtonText)
    setUpdateToServerOverride(updateFunc)
  }, [
    isManualAssignment,
    setSaveButtonText,
    row.mediatorAppointmentDate,
    saveAcceptToDB,
    setUpdateToServerOverride,
  ])

  React.useEffect(() => {
    if (typeof data === 'undefined') return // avoid overriding default recommendation unncessarily
    let nextRecommendation = {}
    const recommendations = data?.['mediatorRecommendations']
    if (recommendations && recommendations.length) {
      nextRecommendation = recommendations[0]
    }
    setRecommendation((currentRec) => (
      deepEqual(currentRec, nextRecommendation) ? currentRec : nextRecommendation
      )
    )
  }, [data])

  React.useEffect(() => {
    const wrappedEventClearValue = wrapSelectChangeEvent(column.name, null, SELECT_FALLBACK_VALUE)
    if (!isManualAssignment && recommendation.mediator && !specificMediator && recommendation.mediator.id != value?.id) {
      const wrappedEvent = wrapSelectChangeEvent(column.name, recommendation.mediator, SELECT_FALLBACK_VALUE)
      onChange(wrappedEvent)
      setRecommended(true)

      setMediatorAppointmentDate(recommendation.acceptedAt ? row.mediatorAppointmentDate : getToday())
    } else if (!recommendation.mediator && recommended && !exhaustedCleared) {
      // exhausted all recommendations
      onChange(wrappedEventClearValue)
      setExhaustedCleared(true)
    } else if (specificMediator && value?.id == recommendation.mediator.id) {
      // another mediator is named by parties or court
      onChange(wrappedEventClearValue)
    }
  }, [
    recommendation,
    column.name,
    onChange,
    value,
    recommended,
    specificMediator,
    setSaveButtonText,
    row.mediatorAppointmentDate,
    exhaustedCleared,
    isManualAssignment,
  ])

  const onDeclineReasonChange = (event) => {
    const rec = {...recommendation}
    rec.rejectReason = event.target.value
    setRecommendation(rec)
  }

  const onDeclineReasonOtherChange = (event) => {
    const rec = {...recommendation}
    rec.rejectReasonOther = event.target.value
    setRecommendation(rec)
  }

  const onPreNextMediatorAlertCancel = () => {
    const rec = {...recommendation}
    rec.rejectReason = null
    rec.rejectReasonOther = null
    setRecommendation(rec)
    setShowDeclineReason(false)
  }

  const recommendationEndAdornment = (
    <InputAdornment position="end">
      {recommendation?.acceptedAt ?
        <Chip label="Accepted" color="primary" size="small" style={{margin: '0 5px'}}/> :
        <AutoAwesomeIcon color="primary" />
      }
    </InputAdornment>
  )

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error loading mediator recommendation. Please refresh the page.</p>;

  if (isManualAssignment) {
    return (
      <MediatorPicker
        column={column}
        availableValues={availableValues}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        row={row}
        required={required || specificMediator}
        recommendation={recommendation}
        setRecommendation={setRecommendation}
        setShowDeclineReason={setShowDeclineReason}
        setMsgObj={setMsgObj}
      />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Smart Mediator Assignment
          <IconButton
            aria-label="info"
            color="info"
            size="small"
            onClick={() => { setOpenInfo(!openInfo) }}
          >
            <InfoOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <Collapse in={openInfo}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => { setOpenInfo(false) }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          The Smart Assignment feature automatically recommends the most suitable mediator.
        </Alert>
      </Collapse>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" margin="normal" fullWidth>
          <SelectEditor
            column={column}
            availableValues={availableValues}
            value={recommendation.mediator}
            row={row}
            readOnly
            readOnlyInputProps={{endAdornment: recommendationEndAdornment}}
          />
        </FormControl>

        {showDeclineReason &&
        <FormControl variant="standard" margin="normal" fullWidth error={!!rejectError}>
          <InputLabel id="decline-reason-label">Decline Reason</InputLabel>
          <Select
            labelId="decline-reason-label"
            id="rejectReason"
            value={recommendation.rejectReason || ''}
            onChange={onDeclineReasonChange}
            label="Decline Reason"
          >
            <MenuItem value="MEDIATOR_DECLINED">{MEDIATOR_DECLINED}</MenuItem>
            <MenuItem value="MEDIATOR_UNREACHABLE">{MEDIATOR_UNREACHABLE}</MenuItem>
            <MenuItem value="PARTIES_REQUESTED">Parties requested a specific mediator</MenuItem>
            <MenuItem value="COURT_NAMED">Court named a specific mediator</MenuItem>
            <MenuItem value="OTHER">{OTHER}</MenuItem>
          </Select>
          <FormHelperText>{rejectError}</FormHelperText>
        </FormControl>

        }

        <FormControl variant="standard" margin="normal" fullWidth>
          <MediatorAppointmentDate mediatorAppointmentDate={mediatorAppointmentDate} />
        </FormControl>

        {!showDeclineReason &&
        <FormControl variant="standard" margin="normal">
          <Button variant="outlined" color="error" onClick= {() => setShowDeclineReason(true)}>
            Decline Mediator
          </Button>
        </FormControl>
        }

        {recommendation.rejectReason == 'OTHER' &&
          <PreNextMediatorAlert alertTitle={OTHER} onCancel={onPreNextMediatorAlertCancel} onNext={validateAndSaveRejectToDB}>
            <FormControl variant="standard" margin="normal" fullWidth>
              <TextField
                multiline
                variant="standard"
                name="other"
                label="Other"
                value={recommendation.rejectReasonOther || ''}
                onChange={onDeclineReasonOtherChange}
                placeholder="Detail reason for declining mediator"
                error={!!rejectOtherError}
                helperText={rejectOtherError}
                autoFocus
                required
            />
            </FormControl>
          </PreNextMediatorAlert>
        }
        {recommendation.rejectReason == 'MEDIATOR_DECLINED' &&
          <PreNextMediatorAlert alertTitle={MEDIATOR_DECLINED} onCancel={onPreNextMediatorAlertCancel} onNext={saveRejectToDB}>
            <p>
              Are you sure the mediator declined the case? A declined mediator will be blocked for 7 days from accepting new cases.
            </p>
          </PreNextMediatorAlert>
        }
        {recommendation.rejectReason == 'MEDIATOR_UNREACHABLE' &&
          <PreNextMediatorAlert alertTitle={MEDIATOR_UNREACHABLE} onCancel={onPreNextMediatorAlertCancel} onNext={saveRejectToDB}>
            <p>
              Are you sure the mediator could not be reached? A unreachable mediator will be blocked for 7 days from accepting new cases.
            </p>
          </PreNextMediatorAlert>
        }
      </Grid>
    </Grid>
  )
}

const PreNextMediatorAlert = ({onCancel, onNext, alertTitle, children}) => {
  const [open, setOpen] = React.useState(true)

  const close = () => {
    onCancel()
    setOpen(false)
  }

  const next = () => {
    const keepOpen = !!onNext()
    setOpen(keepOpen)
  }

  return (
    <Dialog open={open} onClose={close} scroll="paper" maxWidth="sm" fullWidth>
      <DialogTitle id="reject-reason-other-dialog-title">
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button onClick={next} color="primary" endIcon={<AutoAwesomeIcon />}>
          Next Mediator
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MediatorRecommender
