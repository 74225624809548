import { gql } from '@apollo/client';
import { canViewCases, canViewUsers } from "./auth";

const DUMMY_QUERY = gql`query dummy{ dummy { id } }`

// fetching issues is a relatively slow query
const MEDIATOR_FIELDS_WITHOUT_ISSUES = `
  id
  pk
  name
  user {
    id
    name
    email
  }
  phone
  macNumber
  status {
    id
  }
  dateOfBirth
  address {
    id
    postalCode
    poBoxNumber
    city
  }
  professions {
    edges {
      node {
        id
      }
    }
  }
  accreditationCategories {
    edges {
      node {
        id
      }
    }
  }
  professionalMemberships {
    edges {
      node {
        id
      }
    }
  }
  professionalQualifications {
    edges {
      node {
        id
      }
    }
  }
  gender {
    id
  }
  religions {
    edges {
      node {
        id
      }
    }
  }
  languages {
    edges {
      node {
        id
      }
    }
  }
  courtStations {
    edges {
      node {
        id
      }
    }
  }
  dateOfAccreditation
  nationality {
    id
  }
  idPassportNumber
  virtualMediation
  image {
    url
  }
  stats {
    numPendingCases
    numConcludedCases
    numAgreedCases
    agreementRate
  }
  createdAt
  updatedAt
  remark
`
const MEDIATOR_FIELDS = `
  ${MEDIATOR_FIELDS_WITHOUT_ISSUES}
  issues
`

const CASE_FIELDS = `
  id
  pk
  referralDate
  referralMode {
    id
  }
  courtStation {
    id
  }
  courtType {
    id
  }
  courtDivision {
    id
  }
  caseType {
    id
  }
  caseNumber
  originalCaseNumber
  caseValue
  caseValueMode {
    id
  }
  plaintiffName
  plaintiffContact
  plaintiffLanguages {
    edges {
      node {
        id
      }
    }
  }
  defendantName
  defendantContact
  defendantLanguages {
    edges {
      node {
        id
      }
    }
  }
  deceasedName
  mediatorAppointmentDate
  conclusionDate
  completionCertificateDate
  forwardedForPaymentDate
  fileReturnedToCourtDate
  mediator {
    id
    pk
  }
  outcome {
    id
  }
  pendingReason {
    id
  }
  status {
    id
  }
  agreementMode {
    id
  }
  sessionType {
    id
  }
  issues
  remark
  ctsCaseId
  mediatorIsRecommended
`

const CASE_META_FIELDS = `
  referralModes {
    id
    name
  }
  courtDivisions {
    id
    name
  }
  caseTypes {
    id
    name
  }
  caseStatuses {
    id
    name
  }
  caseOutcomes {
    id
    name
  }
  casePendingReasons {
    id
    name
  }
  caseValueModes {
    id
    name
  }
  agreementModes {
    id
    name
  }
  courtTypes {
    id
    name
  }
  sessionTypes {
    id
    name
  }
`
const MEDIATOR_META_FIELDS = `
  professions {
    id
    name
  }
  professionalMemberships {
    id
    name
  }
  professionalQualifications {
    id
    name
  }
  accreditationCategories {
    id
    name
  }
  mediatorStatuses {
    id
    name
  }
  genders {
    id
    name
  }
  religions {
    id
    name
  }
  nationalities {
    id
    name
  }
`
const SHARED_META_FIELDS = `
  languages {
    id
    name
  }
  courtStations {
    id
    name
    code
  }
`
const OFFICER_META_FIELDS = `
  groups {
    id
    name
  }
`

const OFFICER_FIELDS= `
  id
  name
  email
  isActive
  groups {
    edges {
      node {
        id
      }
    }
  }
`

const SHARED_CASE_MEDIATOR_QUERY_PARAMS = {
  orderBy: "String",
  offset: "Int",
  first: "Int",
}

// TODO: generate this from introspection query
const CASE_QUERY_PARAMS = {
  mediatorAppointmentDate: "Date",
  completionCertificateDate: "Date",
  deceasedName: "String",
  forwardedForPaymentDate: "Date",
  fileReturnedToCourtDate: "Date",
  originalCaseNumber: "String",
  defendantName: "String",
  id: "ID",
  referralDate: "Date",
  conclusionDate: "Date",
  plaintiffContact: "String",
  remark: "String",
  caseValue: "Decimal",
  caseSummary: "String",
  caseNumber: "String",
  plaintiffName: "String",
  defendantContact: "String",
  mediator_Phone: "String",
  mediator_User_Email: "String",
  agreementMode: "ID",
  plaintiffLanguages: "[ID]",
  pendingReason: "ID",
  courtType: "ID",
  courtDivision: "ID",
  referralMode: "ID",
  courtStation: "ID",
  caseValueMode: "ID",
  caseValueMode_Name: "String",
  defendantLanguages: "[ID]",
  status: "ID",
  sessionType: "ID",
  caseType: "ID",
  outcome: "ID",
  mediator: "ID",
  search: "String",
}

// TODO: generate this from introspection query
const MEDIATOR_QUERY_PARAMS = {
  virtualMediation: "Boolean",
  phone: "String",
  macNumber: "String",
  idPassportNumber: "String",
  dateOfAccreditation: "Date",
  dateOfBirth: "Date",
  id: "ID",
  stats_NumPendingCases: "Int",
  stats_NumConcludedCases: "Int",
  stats_AgreementRate: "Float",
  address_PostalCode: "String",
  address_PoBoxNumber: "String",
  address_City: "String",
  user_Email: "String",
  user_Name: "String",
  gender: "ID",
  nationality: "ID",
  religions: "[ID]",
  professionalQualifications: "[ID]",
  professions: "[ID]",
  accreditationCategories: "[ID]",
  address: "String",
  stats: "ID",
  languages: "[ID]",
  professionalMemberships: "[ID]",
  status: "ID",
  courtStations: "[ID]",
  user: "ID",
  search: "String",
  remark: "String",
}

// TODO: generate this from introspection query
const OFFICER_QUERY_PARAMS = {
  email: "String",
  name: "String",
  isActive: "Boolean",
  groups: "[ID]",
  search: "String",
}

function getQueryParams(target) {
  let paramMap = {}
  if (target === 'cases') {
    paramMap = {...CASE_QUERY_PARAMS, ...SHARED_CASE_MEDIATOR_QUERY_PARAMS }
  } else if (target === 'mediators') {
    paramMap = {...MEDIATOR_QUERY_PARAMS, ...SHARED_CASE_MEDIATOR_QUERY_PARAMS }
  } else if (target === 'officers') {
    paramMap = OFFICER_QUERY_PARAMS
  }

  // var name to type mapping
  const paramSchema = Object.keys(paramMap).map(k => (
    `$${k}: ${paramMap[k]}`
  )).join(', ')

  // name to var name mapping
  const queryParams = Object.keys(paramMap).map(k => (
    `${k}: $${k}`
  )).join(', ')

  return [paramSchema, queryParams]
}

function getSingleRecordFetchQuery(permissions, target) {
  const singleCaseQuery = (canViewCases(permissions) && target === 'cases') ? `
    case (id: $id) {
      ${CASE_FIELDS}
    }
  ` : ''
  const singleMediatorQuery = (target === 'mediators') ? `
    mediator (id: $id) {
      ${MEDIATOR_FIELDS}
    }
  ` : ''
  const singleOfficerQuery = (canViewUsers(permissions) && target === 'officers') ? `
    officer (id: $id) {
      ${OFFICER_FIELDS}
    }
  ` : ''

  // avoid constructing invalid query - "Syntax Error: Expected Name, found }"
  if (!singleCaseQuery && !singleMediatorQuery && !singleOfficerQuery) {
    return DUMMY_QUERY
  }

  const fetchQuery = gql`
    query Record ($id: ID!) {
      ${singleCaseQuery}${singleMediatorQuery}${singleOfficerQuery}
    }
  `

  return fetchQuery
}

function getFetchQuery(permissions, target) {
  const [paramSchema, queryParams] = getQueryParams(target)
  const caseQuery = (canViewCases(permissions) && target === 'cases') ? `
    allCases (${queryParams}) {
      totalCount
      edges {
        node {
          ${CASE_FIELDS}
        }
      }
    }
  ` : ''

  const mediatorQuery = (target === 'mediators') ? `
    allMediators (${queryParams}) {
      totalCount
      edges {
        node {
          ${MEDIATOR_FIELDS}
        }
      }
    }
  ` : ''

  const officerQuery = (canViewUsers(permissions) && target === 'officers') ? `
    allOfficers (${queryParams}) {
      totalCount
      edges {
        node {
          ${OFFICER_FIELDS}
        }
      }
    }
  ` : ''

  const fetchQuery = gql`
    query Records (${paramSchema}) {
      ${caseQuery}
      ${mediatorQuery}
      ${officerQuery}
    }
  `

  return fetchQuery
}

function getMetadataFetchQuery(permissions) {
  const caseMetadataQuery = (canViewCases(permissions)) ? `
    mediators {
      ${MEDIATOR_FIELDS_WITHOUT_ISSUES}
    }
    ${CASE_META_FIELDS}
  ` : ''

  const officerMetadataQuery = (canViewUsers(permissions)) ? `
    ${OFFICER_META_FIELDS}
  ` : ''

  return gql`
    query Metadata {
      ${officerMetadataQuery}
      ${caseMetadataQuery}
      ${MEDIATOR_META_FIELDS}
      ${SHARED_META_FIELDS}
    }
  `
}

const UNAVAILABILITY_QUERY = gql`
  query mediatorUnavailabilities ($mediatorId: ID!) {
    mediatorUnavailabilities (mediatorId: $mediatorId){
      id
      unavailableDates
      studyAutoBlocked
    }
  }
`

const CREATE_UNAVAILABILITY_QUERY  = gql`
  mutation createMediatorUnavailability(
    $mediator: NestedObjectMutationPayload,
    $unavailableDates: [String],
    $mediationCaseNumber: String,
  ) {
    createMediatorUnavailability(
      mediator: $mediator,
      unavailableDates: $unavailableDates,
      mediationCaseNumber: $mediationCaseNumber,
    ) {
      mediatorUnavailability {
        id
        unavailableDates
      }
    }
  }
`

const UPDATE_UNAVAILABILITY_QUERY  = gql`
  mutation updateMediatorUnavailability(
    $id: ID!,
    $unavailableDates: [String],
    $mediationCaseNumber: String,
  ) {
    updateMediatorUnavailability(
      id: $id,
      unavailableDates: $unavailableDates,
      mediationCaseNumber: $mediationCaseNumber,
    ) {
      mediatorUnavailability {
        id
        unavailableDates
      }
    }
  }
`

const DELETE_UNAVAILABILITY_QUERY  = gql`
  mutation deleteMediatorUnavailability(
    $id: ID!,
    $mediationCaseNumber: String,
  ) {
    deleteMediatorUnavailability(
      id: $id,
      mediationCaseNumber: $mediationCaseNumber,
    ) {
      mediatorUnavailability {
        unavailableDates
      }
    }
  }
`

const ASSIGNEMENT_ISSUES_QUERY = gql`
  query mediatorAssignmentIssues ($caseCourtStationId: ID, $caseTypeId: ID) {
    mediatorAssignmentIssues (caseCourtStationId: $caseCourtStationId, caseTypeId: $caseTypeId){
      mediatorId
      issues
    }
  }
`

function getChangesQuery (name) {
  return gql`
    query ${name}Log ($id: ID!) {
      ${name}Log (${name}Id: $id){
        id
        actor {
          name
        }
        action
        timestamp
        changesStr
      }
    }
  `
}

const CHANGES_QUERIES = {
  mediator: getChangesQuery('mediator'),
  case: getChangesQuery('case'),
  officer: getChangesQuery('officer'),
}

const MEDIATOR_RECOMMENDATIONS_QUERY = gql`
  query mediatorRecommendations ($caseId: ID!) {
    mediatorRecommendations (caseId: $caseId){
      id
      mediator {
        id
        pk
      }
      rank
      acceptedAt
      rejectedAt
      rejectReason
      rejectReasonOther
      rejectReasonCourtNamedBy
      updatedBy {
        id
      }
    }
  }
`

const REJECT_MEDIATOR_RECOMMENDATION_QUERY = gql`
  mutation rejectMediatorRecommendation (
    $mediator: NestedObjectMutationPayload,
    $case: NestedObjectMutationPayload,
    $rejectReason: String!,
    $rejectReasonOther: String,
    $rejectReasonCourtNamedBy: String,
  ) {
    rejectMediatorRecommendation (
      mediator: $mediator,
      case: $case,
      rejectReason: $rejectReason,
      rejectReasonOther: $rejectReasonOther,
      rejectReasonCourtNamedBy: $rejectReasonCourtNamedBy,
    ){
      recommendation {
        mediator {
          id
        }
        case {
          id
        }
        rejectedAt
        rejectReason
        rejectReasonOther
        rejectReasonCourtNamedBy
        updatedBy {
          id
        }
      }
    }
  }
`

const ACCEPT_MEDIATOR_RECOMMENDATION_QUERY = gql`
  mutation acceptMediatorRecommendation (
    $mediator: NestedObjectMutationPayload,
    $case: NestedObjectMutationPayload,
  ) {
    acceptMediatorRecommendation (
      mediator: $mediator,
      case: $case,
    ){
      recommendation {
        mediator {
          id
        }
        case {
          id
        }
        acceptedAt
        updatedBy {
          id
        }
      }
    }
  }
`

const CTS_CASE_SEARCH_QUERY = gql`
  query ctsCaseSearch (
    $courtStationId: ID!,
    $courtTypeId: ID!,
    $originalCaseNumber: String!,
  ) {
    ctsCaseSearch (
      courtStationId: $courtStationId,
      courtTypeId: $courtTypeId,
      originalCaseNumber: $originalCaseNumber,
    ){
      ctsCaseId
      ctsCaseNumber
      ctsCaseType
      filingDate
      citation
    }
  }
`

export {
  MEDIATOR_FIELDS,
  CASE_FIELDS,
  OFFICER_FIELDS,
  getFetchQuery,
  getSingleRecordFetchQuery,
  getMetadataFetchQuery,
  DUMMY_QUERY,
  UNAVAILABILITY_QUERY,
  CREATE_UNAVAILABILITY_QUERY,
  UPDATE_UNAVAILABILITY_QUERY,
  DELETE_UNAVAILABILITY_QUERY,
  ASSIGNEMENT_ISSUES_QUERY,
  CHANGES_QUERIES,
  MEDIATOR_RECOMMENDATIONS_QUERY,
  REJECT_MEDIATOR_RECOMMENDATION_QUERY,
  ACCEPT_MEDIATOR_RECOMMENDATION_QUERY,
  CTS_CASE_SEARCH_QUERY,
}
